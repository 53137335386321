// @flow

import React from 'react';
import type { ComponentType } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { find } from '@riseart/fe-utils';
import { meta as META_ENUM } from 'Enum';
import { mePatch } from 'shared_services/redux/actions/me/me';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { MetaService } from 'shared_services/riseart/meta/Meta';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { getLocaleConfig, normalizeUri } from 'shared_services/riseart/utils/RouteUtils';
import {
  selectAuthShippingCountryCode,
  selectAuthToken,
} from 'shared_services/redux/selectors/auth';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { redirectsSelector } from 'shared_services/redux/selectors/redirects';
import { selectVisitorId, selectVisitorLocale } from 'shared_services/redux/selectors/visitor';
import { selectStoreCode } from 'shared_services/redux/selectors/storeCode';
import { selectUnitSystem } from 'shared_services/redux/selectors/unitSystem';
import { GTM_EVENT_TRIGGER_REASONS } from 'shared_services/redux/middleware/MiddlewareGoogleTagManager';

type Props = {
  actionMePatch: Function,
  visitorId: string | number,
  visitorLocale: string,
  locale: string,
  shippingCountryCode: string,
  storeCode: string,
  unitSystem: string,
  token: string,
  localeRedirects: ?string,
  onSubmit: Function,
};

const HOC_DISPLAY_NAME = 'HOCRegionalForm';

/**
 * HOC
 *
 * @param {class} RegionalForm
 */
function HOC(RegionalForm) {
  return class extends React.Component<Props, Object> {
    static displayName = HOC_DISPLAY_NAME;

    redirectUrl: string | null = null;

    /**
     * constructor
     */
    constructor(props: Props) {
      super(props);

      this.bindMethods();
    }

    /**
     * componentDidUpdate
     *
     * @param {Props} prevProps
     */
    componentDidUpdate(prevProps: Props) {
      // Listen for a change in visitorLocale and application locale,
      // and if this.redirectUrl then the component should redirect to the new localized url
      if (this.redirectUrl && this.props.visitorLocale !== prevProps.locale) {
        LocationManager.redirect(normalizeUri(this.redirectUrl));
        this.redirectUrl = null;
      }
    }

    /**
     * handleComplete
     *
     * Callback function after mutation is complete
     *
     * @param {Object} data
     */
    handleComplete(data: Object) {
      const { token, actionMePatch, locale, localeRedirects, onSubmit } = this.props;
      const newLocale =
        data && data.updateMe && data.updateMe.visitor && data.updateMe.visitor.locale;

      if (typeof onSubmit === 'function') {
        onSubmit();
      }

      // New locale set
      if (newLocale && locale !== newLocale) {
        const newLocaleConfig = getLocaleConfig(newLocale);

        if (!newLocaleConfig) {
          return;
        }

        // get redirect from locale redirects or from hreflang tag, or redirect to homme page
        this.redirectUrl =
          (localeRedirects && localeRedirects[newLocaleConfig.language]) ||
          (
            find(
              MetaService.getMetaByType(META_ENUM.METATYPE.LINK_HREFLANG),
              ({ attributes: { hreflang } }) => hreflang === newLocaleConfig.language,
            ) || {}
          ).content ||
          UrlAssembler.byRouteKey('home', { locale: newLocaleConfig });
      }

      // Trigger ME_PATCH action
      actionMePatch({
        ...data.updateMe,
        trigger: {
          reason: GTM_EVENT_TRIGGER_REASONS.updateRegional,
        },
        currentToken: token,
        // Pass this as visitorId, because the additional
        // middlware routed action to refresh token expect visitorId
        visitorId: data.updateMe.visitor.id,
      });
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.handleComplete = this.handleComplete.bind(this);
    }

    /**
     * render
     */
    render() {
      const { visitorId, locale, shippingCountryCode, storeCode, unitSystem } = this.props;

      return (
        <RegionalForm
          visitorId={visitorId}
          locale={locale}
          shippingCountry={shippingCountryCode}
          store={storeCode}
          unitSystem={unitSystem}
          onComplete={this.handleComplete}
        />
      );
    }
  };
}

/**
 * HOCFormRegional
 *
 * @param {class} RegionalForm
 * @returns {React.Element}
 */
export default function HOCFormRegional(RegionalForm: ComponentType<*>) {
  return connect(
    (store) => ({
      token: selectAuthToken(store),
      visitorId: selectVisitorId(store),
      visitorLocale: selectVisitorLocale(store),
      locale: get(localeSelector(store), 'name'),
      localeRedirects: get(redirectsSelector(store), 'locale'),
      shippingCountryCode: selectAuthShippingCountryCode(store),
      storeCode: selectStoreCode(store),
      unitSystem: selectUnitSystem(store).unitSystem,
    }),
    { actionMePatch: mePatch },
  )(HOC(RegionalForm));
}
