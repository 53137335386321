// @flow

import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { meta as META_ENUM } from 'Enum';
import { generateStaticHrefLang } from 'shared_services/riseart/meta/utils';
import { MetaService, META_SUBSCRIBER_NAME } from 'shared_services/riseart/meta/Meta';
import { LocaleContext } from 'shared_data/providers/locale/Context';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { useUrlParams } from 'shared_data/providers/url/useUrlParams';
import { findDynamicPathParams } from 'shared_services/riseart/utils/RouteUtils';

type Props = {
  isSSR: boolean,
  configMeta: Object,
  pageMeta: ?Object,
  actionGuiUpdate?: Function,
  children?: ?any,
};

/**
 * collectInitialMeta
 * @param {Props} props
 */
function collectInitialMeta({
  isSSR,
  configMeta,
  pageMeta,
  match,
  location,
  currentLocale,
  userLocale,
}: Object) {
  const { metaSubscriptions: configMetaSubscriptions, meta = {}, key: routeKey } = configMeta;
  const initialMetaFromConfig = [
    ...MetaService.permanentMetas(),
    ...Object.keys(meta).map((key: string) => ({ type: key, value: meta[key] })),
  ];
  // get initialMetaSubscriptions from server (if any)
  // and pass them as metaSubscriptions only for the initial client request
  const initialMetaSubscriptions =
    typeof window !== 'undefined' && window.RiseArt && window.RiseArt.initialMetaSubscriptions
      ? window.RiseArt.initialMetaSubscriptions.map(({ timeout, ...rest }) => ({ ...rest }))
      : null;

  if (initialMetaSubscriptions) {
    delete window.RiseArt.initialMetaSubscriptions;
  }

  const initialMeta =
    (typeof window !== 'undefined' &&
      window.RiseArt &&
      window.RiseArt.initialMeta &&
      JSON.parse(decodeURIComponent(window.RiseArt.initialMeta))) ||
    null;

  // Delete inital meta provided by SSR, once it has been read
  if (initialMeta) {
    delete window.RiseArt.initialMeta;
  }

  MetaService.init(
    isSSR
      ? configMetaSubscriptions.map((i) => ({ ...i, timeout: 0 }))
      : initialMetaSubscriptions || configMetaSubscriptions,
    initialMeta,
    { match, location, pageRouteConfig: configMeta, currentLocale, userLocale },
  );

  // This ensures that it will not be run the first time on client
  // when the page was rendered by server and already has initial meta state
  if (!initialMeta) {
    // Reset meta data before adding new data for client routed page
    MetaService.reset();

    // Add static router config meta
    MetaService.add(
      [
        // if url includes dynamic parameters (other than language),
        // then the hreflang rediects cannot be generated on that step
        ...(!findDynamicPathParams(match.path, ['lang']).length
          ? [
              {
                type: META_ENUM.METATYPE.LINK_HREFLANG_REDIRECT,
                value: generateStaticHrefLang(routeKey, {
                  search: location.search,
                  hash: location.hash,
                }),
              },
            ]
          : []),
        // Generate default linkCanonicalRedirect from uri (pathname + search + hash)
        {
          type: META_ENUM.METATYPE.LINK_CANONICAL_REDIRECT,
          value: LocationManager.get('uri'),
        },
        ...initialMetaFromConfig,
      ],
      META_SUBSCRIBER_NAME.ROUTER_META,
    );

    // Add page meta
    if (pageMeta) {
      MetaService.add(
        Object.keys(pageMeta).map((key: string) => ({ type: key, value: pageMeta[key] })),
        META_SUBSCRIBER_NAME.PAGE_META,
      );
    }
  }
}

/**
 * MetaInit
 */
export const MetaInit = ({ children, ...restProps }: Props) => {
  const match = useRouteMatch();
  const { translatedLocation } = useUrlParams();
  const { routeLocale, userLocale } = useContext(LocaleContext);

  collectInitialMeta({
    ...restProps,
    match,
    currentLocale: routeLocale,
    userLocale,
    location: translatedLocation,
  });

  return children || null;
};
