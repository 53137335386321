// @flow

import React from 'react';
import type { Node } from 'react';
import { useSelector } from 'react-redux';
import { PreloaderContentFallback } from '@riseart/common';
import { RouteConfigContext } from 'shared_data/providers/routeconfig/Context';
import { selectCmsModuleByPlaceholder } from 'shared_services/redux/selectors/cms';
import { getCmsModule } from 'shared_components/cms/utils';
import { CMS_FALLBACK_CONFIGS } from 'shared_hocs/cms/views/fallbackConfig';

/**
 * CmsViewModule
 *
 * @param {Object} props
 * @returns
 */
export const CmsViewModule = ({
  placeholder,
  customModuleKey,
  children = null,
  skipFallback = false,
  moduleProps = null,
}: Object): Node => {
  const cmsModule = useSelector(selectCmsModuleByPlaceholder(placeholder));

  if (!cmsModule) {
    return skipFallback ? null : (
      <RouteConfigContext.Consumer>
        {({ key }) => (
          <PreloaderContentFallback {...(CMS_FALLBACK_CONFIGS[`${key}.${placeholder}`] || null)} />
        )}
      </RouteConfigContext.Consumer>
    );
  }

  const ModuleComponent = getCmsModule(customModuleKey || cmsModule.module);

  return ModuleComponent ? (
    <ModuleComponent {...moduleProps} {...cmsModule.content}>
      {children}
    </ModuleComponent>
  ) : null;
};
