// @flow

import React, { Component } from 'react';
import type { Node } from 'react';
import { I18nComponentsLoader } from 'shared_components/i18n/ComponentsLoader';
import { getFilterDomainConfig } from 'shared_models/configs/filters/domains';

/**
 * withIndexedList
 *
 * @param {Class<Component<*, *>>} DecoratedComponent
 */
export function withIndexedList(
  domain: string,
  DecoratedComponent: Class<Component<any, any>>,
): any {
  const { backendDomain } = getFilterDomainConfig(domain) || {};

  return class extends Component<any, any> {
    render(): Node {
      return (
        <I18nComponentsLoader dataKey="metaCategories">
          {(metaCategories) => {
            const domainMetaCategories = metaCategories[backendDomain] || null;

            return <DecoratedComponent {...this.props} indexedUrls={domainMetaCategories} />;
          }}
        </I18nComponentsLoader>
      );
    }
  };
}
