// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import find from 'lodash/find';
import { Button, Link, scrollToElement } from '@riseart/common';
import { LockIcon } from '@riseart/icons';
import { FormItem, FormCheckbox } from '@riseart/form';
import { legal as ENUM_LEGAL } from 'Enum';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { ReadLegalQuery } from 'shared_data/providers/queries/ReadLegal';
import { LegalModal } from 'shared_components/forms/common/LegalModal';
import { TermsSection } from 'shared_components/forms/common/TermsSection';
import { CheckoutActionSection } from 'shared_components/checkout/sections/action/Action';
import { CheckoutInfoStep } from 'shared_components/checkout/steps/Info';
import { CheckoutShippingStep } from 'shared_components/checkout/steps/Shipping';
import { CheckoutBillingStep } from 'shared_components/checkout/steps/Billing';
import { Address as AddressModel } from 'shared_models/Address';
import { GTM_CHECKOUT_STEP_INDEXES } from 'shared_services/redux/middleware/MiddlewareGoogleTagManager';

type Props = Object;
type State = Object;

const HOC_DISPLAY_NAME = 'HOCCheckout';
export const STEP = { info: 'info', shipping: 'shipping', billing: 'billing' };
export const STATE = {
  condensed: 'condensed',
  disabled: 'disabled',
  entry: 'entry',
  edit: 'edit',
  review: 'review',
};

/**
 * HOCCheckout
 */
export const HOCCheckout = () =>
  class extends Component<Props, State> {
    static displayName = HOC_DISPLAY_NAME;

    stepsRefs: Object;

    scrollToInfoStep: Function;

    scrollToShippingStep: Function;

    scrollToBillingStep: Function;

    paymentAdapter: Object;

    /**
     * constructor
     *
     * @param {Props} props
     */
    constructor(props: Props) {
      super(props);

      this.stepsRefs = {
        info: React.createRef(),
        shipping: React.createRef(),
        billing: React.createRef(),
      };

      this.bindMethods();

      this.state = this.getDefaultState();

      const PaymentAdapterClass = this.state.paymentMethodAdapter;
      this.paymentAdapter = new PaymentAdapterClass(
        this.props,
        this.state,
        this.handleError,
        (data) => {
          this.props.setPageLoading(false);
          this.props.actionPlaceOrder({
            order: data,
            cart: this.props.cart,
            cartItems: this.props.cartItems,
          });
          this.props.history.push(UrlAssembler.orderConfirmation(data.id));
        },
      );
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
      // Enter info step. Trigger GTM action for first checkout step
      this.props.actionEnterCheckoutStep({
        cart: this.props.cart,
        step: GTM_CHECKOUT_STEP_INDEXES.begin,
        cartItems: this.props.cartItems,
      });
    }

    /**
     * componentDidUpdate
     *
     * @param {Props} prevProps
     * @param {State} prevState
     */
    componentDidUpdate(prevProps: Props, prevState: State) {
      this.triggerActions(prevProps, prevState);
      this.paymentAdapter.hydrate({ state: this.state, props: this.props });
    }

    /**
     * getDefaultState
     *
     * @returns {State}
     */
    getDefaultState() {
      const { info, shippingAddress, billingAddress } = this.collectData(this.props);

      return {
        reviewMode: false,
        info: {
          state: this.hasFullInfo(info) ? STATE.condensed : STATE.entry,
          wasEdited: false,
          data: info,
        },
        shipping: {
          state: STATE.disabled,
          wasEdited: false,
          data: { ...shippingAddress },
        },
        billing: {
          sameAsShipping: this.areAddressesEqual(shippingAddress, billingAddress),
          state: STATE.disabled,
          wasEdited: false,
          data: { ...billingAddress },
          // Step subsections are not set initially in disable state, but instead
          // the step state will disable them anyway, because it is on higher level
          sections: {
            credit: {
              state: STATE.review,
            },
            payment: {
              state: STATE.entry,
            },
            address: {
              state: STATE.entry,
            },
          },
        },
        paymentMethodAdapter: this.props.paymentMethodAdapter,
        payment: { type: null, isValid: false },
      };
    }

    /**
     * getMatchingAddress
     *
     * @param {Object} shipping
     * @param {Array<?Object>} addressList
     * @returns {?Object}
     */
    getMatchingAddress(shipping: Object, addressList: Array<?Object> = []): ?Object {
      if (!shipping || !addressList) {
        return;
      }

      return find(
        addressList,
        (address) =>
          address &&
          // Required fields
          address.addressLine1 === shipping.streetAddress1 &&
          address.city === shipping.city &&
          address.postcode === shipping.postcode &&
          // Optional fields
          (address.addressLine2 || shipping.streetAddress2
            ? address.addressLine2 === shipping.streetAddress2
            : true) &&
          (address.countryCode || shipping.countryCode
            ? address.countryCode === shipping.countryCode
            : true) &&
          (address.county || shipping.county ? address.county === shipping.county : true) &&
          (address.countyCode || shipping.countyCode
            ? address.countyCode === shipping.countyCode
            : true) &&
          (address.company || shipping.company ? address.company === shipping.company : true),
      );
    }

    /**
     * getSavedAddress
     *
     * @param {string | number} id
     * @returns {?Object}
     */
    getSavedAddress(id: string | number): ?Object {
      const { addresses } = this.props;

      if (id && addresses) {
        return find(addresses, ({ id: addressId }) => parseInt(addressId, 10) === parseInt(id, 10));
      }
    }

    /**
     * getSavedCard
     *
     * @param {string | number} id
     * @returns {?Object}
     */
    getSavedCard(id: string | number): ?Object {
      const { cards } = this.props;

      if (id && cards) {
        return find(cards, ({ id: cardsId }) => parseInt(cardsId, 10) === parseInt(id, 10));
      }
    }

    /**
     * triggerActions
     *
     * @param {Props} prevProps
     * @param {State} prevState
     */
    triggerActions(prevProps: Props, prevState: State) {
      const { reviewMode, info, shipping, billing } = this.state;
      const { cart, cartItems, actionEnterCheckoutStep, actionUpdateCheckoutStep } = this.props;
      const {
        reviewMode: prevReviewMode,
        info: prevInfo,
        shipping: prevShipping,
        billing: prevBilling,
      } = prevState;
      const enteredCardType = get(billing, 'data.cardType');
      const selectedPaymentCard =
        (get(billing, 'data.userPaymentId') && this.getSavedCard(billing.data.userPaymentId)) ||
        null;
      const countryCode =
        (shipping.data.userAddressId &&
          get(this.getSavedAddress(shipping.data.userAddressId), 'countryCode')) ||
        shipping.data.countryCode;

      // Enter steps
      const ENTER_REVIEW_MODE = prevReviewMode === false && reviewMode === true;
      const ENTER_INFO = prevInfo.state !== STATE.edit && info.state === STATE.edit;
      const ENTER_SHIPPING =
        prevShipping.state === STATE.disabled && shipping.state !== STATE.disabled;
      const ENTER_BILLING =
        prevBilling.state === STATE.disabled && billing.state !== STATE.disabled;

      // Update steps
      const UPDATE_INFO = prevInfo.state !== STATE.review && info.state === STATE.review;
      const UPDATE_SHIPPING =
        prevShipping.state !== STATE.review && shipping.state === STATE.review;
      const UPDATE_BILLING = prevBilling.state !== STATE.review && billing.state === STATE.review;

      if (UPDATE_INFO || UPDATE_SHIPPING || UPDATE_BILLING) {
        actionUpdateCheckoutStep({
          cart,
          cartItems,
          countryCode,
          enteredCardType,
          selectedPaymentCard,
          step:
            (UPDATE_INFO && GTM_CHECKOUT_STEP_INDEXES.info) ||
            (UPDATE_SHIPPING && GTM_CHECKOUT_STEP_INDEXES.shipping) ||
            (UPDATE_BILLING && GTM_CHECKOUT_STEP_INDEXES.billing),
          formData: this.state,
        });
      }

      if (ENTER_INFO || ENTER_SHIPPING || ENTER_BILLING || ENTER_REVIEW_MODE) {
        actionEnterCheckoutStep({
          cart,
          cartItems,
          countryCode,
          enteredCardType,
          selectedPaymentCard,
          step:
            (ENTER_INFO && GTM_CHECKOUT_STEP_INDEXES.info) ||
            (ENTER_SHIPPING && GTM_CHECKOUT_STEP_INDEXES.shipping) ||
            (ENTER_BILLING && GTM_CHECKOUT_STEP_INDEXES.billing) ||
            (ENTER_REVIEW_MODE && GTM_CHECKOUT_STEP_INDEXES.review),
          formData: this.state,
        });
      }
    }

    /**
     * handleNextStep
     * Update current step state and calculate next step state
     *
     * @param {string} step
     */
    handleNextStep: Function;

    handleNextStep(step: string) {
      return () => {
        if (step === STEP.info) {
          this.setState(
            (prevState) => ({
              info: { ...prevState.info, state: STATE.review },
              shipping: {
                ...prevState.shipping,
                data: {
                  ...prevState.shipping.data,
                  // try to find a match between shipping data and saved addresses
                  userAddressId: get(
                    this.getMatchingAddress(prevState.shipping.data, this.props.addresses),
                    'id',
                    null,
                  ),
                },
                state: this.hasFullAddress(STEP.shipping) ? STATE.condensed : STATE.entry,
              },
            }),
            this.scrollToInfoStep,
          );
        } else if (step === STEP.shipping) {
          const { cards } = this.props;

          this.setState(
            (prevState) => ({
              shipping: { ...prevState.shipping, state: STATE.review },
              billing: {
                ...prevState.billing,
                state:
                  this.hasFullAddress(STEP.billing) && this.hasFullPayment()
                    ? STATE.condensed
                    : STATE.entry,
                sections: {
                  ...prevState.billing.sections,
                  payment: {
                    state: cards && cards.length ? STATE.review : STATE.edit,
                  },
                  // If no full billing address - show billing address form
                  ...(!this.hasFullAddress(STEP.billing) ? { address: { state: STATE.edit } } : {}),
                },
              },
            }),
            this.scrollToShippingStep,
          );
        } else if (step === STEP.billing) {
          this.setState(
            (prevState) => ({
              reviewMode: true,
              billing: {
                ...prevState.billing,
                state: STATE.review,
                sections: {
                  credit: { state: STATE.review },
                  payment: { state: STATE.review },
                  address: { state: STATE.review },
                },
              },
            }),
            this.scrollToBillingStep,
          );
        }
      };
    }

    /**
     * handlePaymentStateChange
     *
     * @param {string} step
     * @param {string} state
     */
    handlePaymentStateChange: Function;

    handlePaymentStateChange(step: string, state: string) {
      // Handles billing step subsections state change
      this.setState((prevState) => {
        return {
          billing: {
            ...prevState.billing,
            sections: {
              ...prevState.billing.sections,
              [step]: {
                ...prevState.billing.sections[step],
                state,
              },
              // If payment subsection is in edit mode, check if has full address and if not, show edit form
              ...(step === 'payment' && state === STATE.edit && !this.hasFullAddress(STEP.billing)
                ? { address: { state: STATE.edit } }
                : {}),
            },
          },
        };
      });
    }

    /**
     * handlePaymentChange
     */
    handlePaymentChange: Function;

    handlePaymentChange(payment: Object) {
      this.setState({ payment });
    }

    /**
     * handleStateChange
     *
     * @param {string} step
     * @returns {Function}
     */
    handleStateChange: Function;

    handleStateChange(step: string): Function {
      /**
       * @param {string} state
       * @return {Function}
       */
      return (state: string): Function =>
        () => {
          this.setState((prevState) => {
            const isReviewMode = prevState.reviewMode === true;
            let newState = {};

            if (step === STEP.info) {
              newState = {
                ...newState,
                shipping: {
                  ...prevState.shipping,
                  state: !isReviewMode ? STATE.disabled : prevState.shipping.state,
                },
                billing: {
                  ...prevState.billing,
                  state: !isReviewMode ? STATE.disabled : prevState.billing.state,
                },
              };
            } else if (step === STEP.shipping) {
              newState = {
                ...newState,
                info: {
                  ...prevState.info,
                  state: !isReviewMode ? STATE.review : prevState.info.state,
                },
                billing: {
                  ...prevState.billing,
                  state: !isReviewMode ? STATE.disabled : prevState.billing.state,
                  sections: {
                    ...prevState.billing.sections,
                    ...(!this.hasFullAddress(STEP.billing)
                      ? { address: { state: STATE.edit } }
                      : {}),
                  },
                },
              };
            } else if (step === STEP.billing) {
              newState = {
                ...newState,
                billing: {
                  ...prevState.billing,
                  state,
                  sections: {
                    credit: {
                      state: STATE.review,
                    },
                    payment: {
                      state: STATE.review,
                    },
                    address: {
                      state:
                        state === STATE.edit && !this.requiresPayment() ? STATE.edit : STATE.review,
                    },
                  },
                },
              };
            }

            return {
              [step]: { ...prevState[step], state },
              ...newState,
            };
          });
        };
    }

    /**
     * handleDataChange
     *
     * Collects data from state and forms and call mutations if required
     *
     * @param {string} step
     * @param {Object} data
     */
    handleDataChange: Function;

    handleDataChange(step: string, data: Object): Function {
      if (step === STEP.info) {
        this.updateStateData(step, data);
      } else if (step === STEP.shipping) {
        const { userAddressId: pickedSavedAddressId, saveShippingAddress, ...restData } = data;

        // Check if address exists in saved addresses
        const userAddressId =
          pickedSavedAddressId ||
          get(this.getMatchingAddress(restData, this.props.addresses), 'id', null);

        // Submits shipping address to cart
        this.props.updateCart(
          this.props.mutations.updateCartShipping,
          {
            shippingAddress: userAddressId
              ? {
                  userAddressId,
                  firstname: restData.firstname,
                  lastname: restData.lastname,
                  email: restData.email,
                  telephone: restData.telephone,
                }
              : AddressModel.mapFormToApiData(restData),
          },
          () => this.updateStateData(step, data),
        );
      } else if (step === STEP.billing) {
        const {
          userPaymentId,
          streetAddress1,
          streetAddress2,
          city,
          county,
          postcode,
          countryCode,
        } = data;
        const { billing: billingState, info: infoState } = this.state;

        // Collect user data from billingState
        const userData = {
          firstname: billingState.data.firstname,
          lastname: billingState.data.lastname,
          email: billingState.data.email,
          telephone: billingState.data.telephone || infoState.data.telephone,
          company: billingState.data.company || infoState.data.company,
        };

        // Submits billing address to cart
        this.props.updateCart(
          this.props.mutations.updateCartBilling,
          {
            // Combine billingState user data and combine it with form submit data
            billingAddress: userPaymentId
              ? { ...userData, userPaymentId, paymentMethod: this.props.paymentMethodAdapter }
              : AddressModel.mapFormToApiData({
                  ...userData,
                  streetAddress1: streetAddress1 || billingState.data.streetAddress1,
                  streetAddress2: streetAddress2 || billingState.data.streetAddress2,
                  city: city || billingState.data.city,
                  county,
                  postcode: postcode || billingState.data.postcode,
                  countryCode: countryCode || billingState.data.countryCode,
                }),
          },
          () => this.updateStateData(step, data),
        );
      }
    }

    /**
     * updateStateData
     *
     * @param {string} step
     * @param {Object} newData
     */
    updateStateData(step: string, newInputData: Object) {
      this.setState(
        (prevState) => {
          const prevStepState = prevState[step];
          const newData = { ...newInputData, countyCode: newInputData.county };

          return {
            [step]: {
              ...prevStepState,
              wasEdited: true,
              state: STATE.review,
              data: {
                ...prevStepState.data,
                ...newData,
                // Set userAddressId if no saved address is selected and submit data is same as one of the saved addresses
                ...(step === STEP.shipping && !newData.userAddressId
                  ? {
                      userAddressId: get(
                        this.getMatchingAddress(newData, this.props.addresses),
                        'id',
                        null,
                      ),
                    }
                  : {}),
              },
            },
            // Update billing address with delivery address data if billing address is not complete
            ...(step === STEP.shipping && !this.hasFullAddress(STEP.billing)
              ? {
                  billing: {
                    ...prevState.billing,
                    data: {
                      ...prevState.billing.data,
                      city: newData.city,
                      countryCode: newData.countryCode,
                      county: newData.county || null,
                      countyCode: newData.countyCode || null,
                      postcode: newData.postcode,
                      streetAddress1: newData.streetAddress1,
                      streetAddress2: newData.streetAddress2,
                    },
                  },
                }
              : null),
            // Update billing data based on entered data on info step
            ...(step === STEP.info
              ? {
                  billing: {
                    ...prevState.billing,
                    data: {
                      ...prevState.billing.data,
                      firstname: newData.firstname,
                      lastname: newData.lastname,
                      email: newData.email,
                      telephone: newData.telephone,
                      company: newData.company,
                    },
                  },
                }
              : null),
            // Update personal data (if not set) for shipping address taken from data in info step
            ...(step === STEP.info && !this.hasFullPersonalData(STEP.shipping)
              ? {
                  shipping: {
                    ...prevState.shipping,
                    data: {
                      ...prevState.shipping.data,
                      email: newData.email,
                      firstname: newData.firstname,
                      lastname: newData.lastname,
                      telephone: newData.telephone,
                      company: newData.company,
                    },
                  },
                }
              : null),
          };
        },
        () => {
          if (!this.state.reviewMode) {
            this.handleNextStep(step)();
          } else {
            scrollToElement(this.stepsRefs[step]);
          }
        },
      );
    }

    /**
     * handleLegalRental
     *
     * @param {number} legalAgreementId
     */
    handleLegalRental: Function;

    handleLegalRental(legalAgreementId: number) {
      /**
       *
       * @param {Object} event
       */
      return (event: Object) =>
        this.setState({ legalAgreementId: event.target.checked ? legalAgreementId : null });
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.handleNextStep = this.handleNextStep.bind(this);
      this.handleStateChange = this.handleStateChange.bind(this);
      this.handlePaymentChange = this.handlePaymentChange.bind(this);
      this.handlePaymentStateChange = this.handlePaymentStateChange.bind(this);
      this.handleDataChange = this.handleDataChange.bind(this);
      this.handleLegalRental = this.handleLegalRental.bind(this);
      this.handlePlaceOrder = this.handlePlaceOrder.bind(this);

      this.scrollToInfoStep = scrollToElement.bind(this, this.stepsRefs[STEP.info]);
      this.scrollToShippingStep = scrollToElement.bind(this, this.stepsRefs[STEP.shipping]);
      this.scrollToBillingStep = scrollToElement.bind(this, this.stepsRefs[STEP.billing]);
    }

    /**
     * collectData
     *
     * Collect initial data for all steps
     *
     * @param {Props} props
     */
    collectData({ cart, user: userProp }: Props) {
      const billingAddress = get(cart, 'billingAddress') || {};
      const shippingAddress = get(cart, 'shippingAddress') || {};
      const user = userProp || {};
      const info = {
        firstname: billingAddress.firstname || user.firstName,
        lastname: billingAddress.lastname || user.lastName,
        email: billingAddress.email || user.email,
        telephone: billingAddress.telephone || user.phone,
        company: billingAddress.company,
      };

      return {
        info,
        shippingAddress: {
          email: shippingAddress.email || info.email,
          firstname: shippingAddress.firstname || info.firstname,
          lastname: shippingAddress.lastname || info.lastname,
          telephone: shippingAddress.telephone || info.telephone,
          company: shippingAddress.company || info.company,
          customerNotes: shippingAddress.customerNotes,
          ...this.collectAddressData(
            {
              city: shippingAddress.city,
              countryCode: shippingAddress.countryCode,
              county: shippingAddress.county,
              countyCode: shippingAddress.countyCode,
              postcode: shippingAddress.postcode,
              streetAddress1: shippingAddress.streetAddress1,
              streetAddress2: shippingAddress.streetAddress2,
            },
            {
              city: billingAddress.city,
              countryCode: billingAddress.countryCode,
              county: billingAddress.county,
              countyCode: billingAddress.countyCode,
              postcode: billingAddress.postcode,
              streetAddress1: billingAddress.streetAddress1,
              streetAddress2: billingAddress.streetAddress2,
            },
          ),
        },
        billingAddress: {
          email: billingAddress.email || info.email,
          firstname: billingAddress.firstname || info.firstname,
          lastname: billingAddress.lastname || info.lastname,
          telephone: billingAddress.telephone || info.telephone,
          company: billingAddress.company || info.company,
          ...this.collectAddressData(
            {
              city: billingAddress.city,
              countryCode: billingAddress.countryCode,
              county: billingAddress.county,
              countyCode: billingAddress.countyCode,
              postcode: billingAddress.postcode,
              streetAddress1: billingAddress.streetAddress1,
              streetAddress2: billingAddress.streetAddress2,
            },
            {
              city: shippingAddress.city,
              countryCode: shippingAddress.countryCode,
              county: shippingAddress.county,
              countyCode: shippingAddress.countyCode,
              postcode: shippingAddress.postcode,
              streetAddress1: shippingAddress.streetAddress1,
              streetAddress2: shippingAddress.streetAddress2,
            },
          ),
        },
      };
    }

    /**
     * collectAddressData
     * Checks if sourceAdd has any of its values set, and if not it falls back to fallbackAddr
     *
     * @param {Object} sourceAddr
     * @param {Object} fallbackAddr
     *
     * @returns {Object}
     */
    collectAddressData(sourceAddr: Object = {}, fallbackAddr: Object = {}): Object {
      if (
        sourceAddr.streetAddress1 ||
        sourceAddr.streetAddress2 ||
        sourceAddr.city ||
        sourceAddr.county ||
        sourceAddr.postcode
      ) {
        return { ...sourceAddr };
      }

      return { ...fallbackAddr };
    }

    /**
     * hasFullInfo
     *
     * @param {Object} info
     * @returns {boolean}
     */
    hasFullInfo(info?: Object): boolean {
      const { data } = info ? { data: info } : this.state.info;

      return !!(data.firstname && data.lastname && data.email && data.telephone);
    }

    /**
     * hasFullAddress
     *
     * @param {sting} step
     * @returns {boolean}
     */
    hasFullAddress(step: string): boolean {
      const { data } = this.state[step] || {};

      return !!(data && data.streetAddress1 && data.city && data.postcode && data.countryCode);
    }

    /**
     * hasFullPersonalData
     *
     * @param {string} step
     * @returns {boolean}
     */
    hasFullPersonalData(step: string): boolean {
      const { data } = this.state[step] || {};

      return !!(data && data.email && data.firstname && data.lastname && data.telephone);
    }

    /**
     * hasFullPayment
     *
     * @returns {boolean}
     */
    hasFullPayment(): boolean {
      const { data } = this.state.billing;

      return (
        !this.requiresPayment() ||
        data.userPaymentId ||
        (data.cardType &&
          data.cardOwner &&
          data.cardNumber &&
          data.cardValidThru &&
          data.cardSecurity)
      );
    }

    /**
     * requiresPayment
     *
     * @returns {boolean}
     */
    requiresPayment(): boolean {
      const { cart } = this.props;

      return (cart && cart.totalAmount > 0) || (cart.totalAmount <= 0 && cart.useCredit);
    }

    /**
     * areAddressesEqual
     *
     * @param {Object} address1
     * @param {Object} address2
     * @param {Array<string>} compareFields
     * @returns {boolean}
     */
    areAddressesEqual(
      address1: Object,
      address2: Object,
      compareFields: Array<string> = [
        'streetAddress1',
        'streetAddress2',
        'city',
        'postcode',
        'countryCode',
      ],
    ): boolean {
      if (!address1 || !address2) {
        return false;
      }

      return compareFields.every((field) => address1[field] === address2[field]);
    }

    /**
     * areAllStepsInReview
     *
     * @returns {boolean}
     */
    areAllStepsInReview(): boolean {
      const { info, shipping, billing } = this.state;

      return (
        info.state === STATE.review &&
        shipping.state === STATE.review &&
        billing.state === STATE.review
      );
    }

    /**
     * handleError
     *
     * @param {Object} error
     */
    handleError(error: Object) {
      this.props.setPageLoading(false);
      this.props.actionErrorAdd([{ type: 'error', message: error.message }]);

      // Open billing step if error type is card_error
      if (error && error.type === 'card_error') {
        this.setState((prevState) => {
          return {
            billing: {
              ...prevState.billing,
              state: STATE.entry,
              sections: {
                ...prevState.billing.sections,
                payment: {
                  state: STATE.edit,
                },
              },
            },
          };
        }, this.scrollToBillingStep);
      }
    }

    /**
     * handlePlaceOrder
     */
    handlePlaceOrder: Function;

    handlePlaceOrder = async () => {
      this.paymentAdapter.hydrate({ props: this.props, state: this.state }).placeOrder();
    };

    /**
     * render
     */
    render() {
      const { isRent, isPageLoading } = this.props;
      const { reviewMode } = this.state;
      const { totalAmount, useCredit } = this.props.cart || {};
      const commonStepProps = {
        onDataUpdate: this.handleDataChange,
        loading: this.props.loading,
        disabled: this.props.disabled,
      };
      const shippingCountryCode = get(this.state[STEP.shipping], 'data.countryCode');

      return (
        <React.Fragment>
          <span ref={this.stepsRefs[STEP.info]} />
          <CheckoutInfoStep
            {...this.state[STEP.info]}
            {...commonStepProps}
            shippingCountryCode={shippingCountryCode}
            onNextStep={this.handleNextStep(STEP.info)}
            onStateChange={this.handleStateChange(STEP.info)}
            reviewMode={this.state.reviewMode}
          />
          <span ref={this.stepsRefs[STEP.shipping]} />
          <CheckoutShippingStep
            {...this.state[STEP.shipping]}
            {...commonStepProps}
            shippingCountryCode={shippingCountryCode}
            onNextStep={this.handleNextStep(STEP.shipping)}
            onStateChange={this.handleStateChange(STEP.shipping)}
            onViewChange={this.scrollToShippingStep}
            addresses={this.props.addresses}
            isRent={isRent}
          />
          <span ref={this.stepsRefs[STEP.billing]} />
          <CheckoutBillingStep
            {...this.state[STEP.billing]}
            {...commonStepProps}
            paymentData={this.state.payment}
            paymentAdapterInstance={this.paymentAdapter}
            paymentMethodAdapter={this.state.paymentMethodAdapter}
            onNextStep={this.handleNextStep(STEP.billing)}
            onStateChange={this.handleStateChange(STEP.billing)}
            handlePaymentStateChange={this.handlePaymentStateChange}
            handlePaymentChange={this.handlePaymentChange}
            hasFullBillingAddress={this.hasFullAddress(STEP.billing)}
            onLoading={this.props.onLoading}
            cards={this.props.cards}
            requiresPayment={this.requiresPayment()}
            useCredit={useCredit}
            total={totalAmount}
            id={this.props.cart && this.props.cart.id}
            customerCreditValue={this.props.cart && this.props.cart.customerCredit}
            storeCode={this.props.storeCode}
            cartType={this.props.cartType}
            visitorId={this.props.visitorId}
            store={this.props.storeCode}
          />

          {reviewMode ? (
            <CheckoutActionSection>
              <ReadLegalQuery skip={!isRent} type={ENUM_LEGAL.type.LEGAL_TYPE_RENTAL}>
                {({ data: rentalLegalData }) => (
                  <React.Fragment>
                    {isRent ? (
                      <FormItem>
                        <FormCheckbox
                          onChange={this.handleLegalRental(rentalLegalData && rentalLegalData.id)}
                        >
                          <LegalModal>
                            {({ toggleModal }) => (
                              <React.Fragment>
                                <FormattedMessage
                                  id="forms.common.confirmRental"
                                  values={{
                                    rental: (
                                      <Button
                                        type="link"
                                        onClick={toggleModal(ENUM_LEGAL.type.LEGAL_TYPE_RENTAL)}
                                      >
                                        <FormattedMessage id="forms.common.rentalAgreement" />
                                      </Button>
                                    ),
                                  }}
                                />
                                <span> *</span>
                              </React.Fragment>
                            )}
                          </LegalModal>
                        </FormCheckbox>
                      </FormItem>
                    ) : null}
                    <Button
                      multiline
                      disabled={
                        !(
                          this.hasFullInfo() &&
                          (this.state.shipping.data.userAddressId ||
                            this.hasFullAddress(STEP.shipping)) &&
                          (this.state.billing.data.userPaymentId ||
                            this.hasFullAddress(STEP.billing)) &&
                          this.areAllStepsInReview()
                        ) ||
                        isPageLoading ||
                        (isRent && !this.state.legalAgreementId)
                      }
                      width="container"
                      size="xlarge"
                      onClick={this.handlePlaceOrder}
                    >
                      <LockIcon /> {/* eslint-disable-line */}
                      {this.paymentAdapter.getCheckoutButtonText()}
                    </Button>
                  </React.Fragment>
                )}
              </ReadLegalQuery>
              <TermsSection
                translationKey="components.checkout.placeOrderHint"
                translationValues={{
                  returnPolicy: (
                    <FormattedMessage id="forms.common.returnPolicies">
                      {(text: string) => (
                        <Link
                          external
                          target="_blank"
                          href={LocalizedConfig.get('externalUrls.returnsHelp')}
                          title={text}
                        >
                          {text}
                        </Link>
                      )}
                    </FormattedMessage>
                  ),
                }}
              />
            </CheckoutActionSection>
          ) : null}
        </React.Fragment>
      );
    }
  };
